import React from 'react'
import { Wrapper } from './Styled-components'

const Content = () => {
  return (
    <>
      <Wrapper>
        <h1>Suada Data Protection Policy (GDPR)</h1>
        <h6>1. Introduction – responsibilities of Suada</h6>
        <p>
          1.1 In the course of undertaking its business activities, Suada
          collects, receives and processes information a) about its customers,
          being individuals who use the services of Suada as a service provider
          (“Customers”) and b) about the customers and clients of its Customers
          (“Consumers”) who, through the Customers, link into and use the Suada
          platforms and software functionality as part of the services that the
          Customers provide to their own Consumers and team. Suada is legally
          responsible for ensuring that this information ("personal data") is
          held and processed in accordance with the law and with individuals'
          rights.
        </p>
        <p>
          1.2 This Policy sets out how Suada complies with the key rules
          governing the use of such data, including the requirements of the
          General Data Protection Regulation 2018 ("GDPR"). For the purposes of
          this Policy, "Data Protection Legislation" shall be taken to mean GDPR
          and any UK law concerning the protection of personal data, including
          any legislation which supplements or replaces GDPR and the Data
          Protection Act 1998 and laws relating to E-Privacy. In preparing this
          Policy, Suada has taken into account guidance available at the time
          from the Information Commissioner's Office (“ICO”) and has taken
          advice.
        </p>
        <p>
          1.3 Words and phrases, such as "data controller" and "data processor"
          as used in this Policy shall have the meanings given to them in GDPR.
        </p>
        <p>
          1.4 Suada believes it is principally a "data processor" for personal
          data it holds and, with its Customers agreement, is required to hold
          that data for the Customer’s purposes. It also processes data of
          Consumers through their registration to the Customer and enables
          access to Suada’s services. However, Suada can also collect and hold
          such personal data itself, at which time it would be a "data
          controller".
        </p>
        <p>
          1.5 Suada also processes the personal data of employees or appointed
          agents or consultants to Suada who in effect work as part of Suada and
          the term "employee" will be used to refer to such persons.
        </p>
        <p>
          1.6 This Policy does not document every part of GDPR which may be
          relevant, but focuses on the key parts applicable to Suada and its aim
          is to make Suada compliant and to eliminate so far as is reasonably
          possible potential Data Protection Legislation breaches by Suada and
          any harm or loss to Customers, Consumers or the employees of Suada.
        </p>
        <p>
          1.7 Suada may review and amend this policy from time to time as it
          thinks fit, and will review it on at least an annual basis
        </p>
        <h6>2. Data protection principles</h6>
        <p>
          Under Data Protection Legislation, Suada is responsible for ensuring
          that personal data is held and processed in accordance with the Data
          Protection Legislation. In summary, these principles are that Personal
          Data:
        </p>
        <ul>
          <li>
            (a.) Should be processed lawfully, fairly and in a transparent
            manner
          </li>
          <li>
            (b.) Should be collected for specified, explicit and legitimate
            purposes, and must be processed in accordance with those purposes
          </li>
          <li>
            (c.) Should be adequate, relevant and limited to what is actually
            necessary for the legitimate purpose for which it is collected
          </li>
          <li>(d.) Must be accurate and kept up to date</li>
          <li>
            (e.) will be stored for no longer than is necessary and in a form
            that permits identification of data subjects
          </li>
          <li>(f.) must be processed in a lawful manner</li>
          <li>
            (g.) shall be subject to appropriate security and safety measures.
          </li>
        </ul>
        <p>
          For the purposes of GDPR, “processing” includes collection and storing
          personal data.{' '}
        </p>
        <h6>3. Lawfulness of processing and obtaining consent</h6>
        <p>
          3.1 When processing personal data, under Article 6 GDPR, Suada may
          only process where one or more lawful grounds apply.
        </p>
        <p>
          3.2 Having considered GDPR and the business activities of Suada, Suada
          has concluded that its processing of personal data in undertaking its
          business activities is lawful on the following GDPR grounds:
        </p>
        <ul>
          <li>
            (a) the processing is necessary for the performance of a contract to
            which the data subject, being either a Customer or a Consumer, is
            party or will be (see Article 6(1)(b) GDPR). Suada’s ‘Subscription
            Agreement’ is its direct contract created online with a Customer
            allowing the Customer to provide services to its Consumers; the
            Customer’s contract with a Consumer which contractually provides
            Consumers access to and use of Suada’s platforms, software and
            services, is the contract to which the Consumer as a data subject is
            party.
          </li>
          <li>
            (b) the processing is necessary for the purposes of the legitimate
            interests (for the purposes of Article 6(1(f) GDPR) pursued by Suada
            as instructed by its Customers on agreed terms in providing its
            services to Customers (and allowing the Consumers direct access to
            Suada’s platforms and software) to help the Customers provide their
            own services to the Consumers. Providing such services for clients
            would not be possible without Suada controlling and processing the
            Customer’s and the Consumer’s personal data, reviewing and
            evaluating the information to assist the Consumer in receiving the
            fitness and training services of Suada. Clients clearly benefit from
            Suada’s services and assistance in relation to which they have used
            Suada; and
          </li>
          <li>
            (c) separately, Suada processes employee’s personal data for the
            purposes of their salaries, bonuses, pensions and their employment
            records generally.
          </li>
        </ul>

        <p>
          3.3 As a result, Suada’s management has reasonably concluded that the
          legal basis for it processing personal data will be individual
          consent, except where this is otherwise necessary (see section 3.4
          below) and that such consent is given by:
        </p>
        <ul>
          <li>
            (a) the Customer executing the Subscription Agreement in which Data
            Protection Legislation binding provisions and safeguards will be
            agreed
          </li>
          <li>(b) the Consumer:</li>
          <ul className="inner">
            <li>
              (i) executing its agreement in whatever form with the customer in
              which Suada will require the Customer to obtain the positive
              consent of the Consumer to allow Suada to hold and process the
              Consumer’s personal; data; and/or
            </li>
            <li>
              (ii) when first accessing Suada’s online website and services and
              signing up as a registered user, the Consumer giving a positive
              consent to Suada holding and using their personal data for the
              specific reasons concerned.
            </li>
          </ul>
        </ul>
        <p>
          3.4 As it is possible that Consumers may submit to Suada certain
          special categories of personal data and in particular medical records
          or histories, then Suada recognises the need to obtain such explicit
          consent to the processing of that type of personal data for the
          purposes of Article 9 GDPR as described in paragraph 3.3(b)(ii) above.
        </p>
        <h6>4. Record keeping</h6>
        <p>
          4.1 Suada is required to maintain a record of its processing of
          personal data activities containing specified information (see Article
          30 GDPR). To enable Suada to comply with this requirement it will:
        </p>
        <ul>
          <li>
            (a) ascertain what personal data is held by Suada and which
            employees may have access to it or involvement (for the purposes of
            providing Suada’s services to the Customer and the Consumer);
          </li>
          <li>
            (b) analyse what personal data may be transferred on by Suada or
            processed for Suada by a third party, for what reason and
            identifying such person or organisation ("Data Processors");
          </li>
          <li>
            (c) have each Data Processor comply with the GDPR provisions in
            respect of a data processor as regards (among other things), the
            categories of processing carried out for Suada; what procedures are
            in place to test and maintain accuracy of the personal data; and
            whether the personal data controlled or processed by the Data
            Processor is (or may be) transferred to a third party processor or
            transferred outside the European Economic Area.
          </li>
        </ul>
        <h6>5. Retention of data</h6>
        <p>
          5.1 Suada recognises that personal data should not be held longer than
          is necessary. In general terms, very little physical hard copy
          personal data is held at all, and if so it is for a variety of periods
          of time depending upon the nature and type of the matter concerned.
          Such physical information – and thus the hard copy personal data
          within it - will be kept by Suada for six years and then destroyed as
          such minimum period of time is required from tax and regulatory rules,
          guidance, codes and good industry practice, in addition to the fact
          that six years is often the limitation period in relation to claims.
          Suada’s public liability insurers also require it.
        </p>
        <p>
          5.2 As regards personal data in electronic digital form, the same
          principles apply. Digital personal data is securely encrypted and
          password-protected using one of the leading tailored IT software
          systems and Suada will keep abreast of technological developments.
        </p>

        <h6>6. Privacy notices</h6>
        <p>
          6.1 Suada will ensure that all Customers receive, in their
          Subscription Agreement with Suada, full notice and details under
          Article 14 GDPR containing information about how the Customer’s
          personal data (and that of the Consumers) will be used. It will also
          contain a tick box under which they give Suada consent to use their
          personal data to send them Suada’s marketing and promotional
          information.
        </p>
        <p>
          6.2 Suada will ensure that the terms of the Subscription Agreement
          will also contain clear instructions to the Customers that their
          specific contracts with their Consumers shall contain suitable,
          adequate and appropriate express positive consent being given by the
          Consumer at sign up/registration stage to their personal data (and any
          special data) being passed to, held and processed by Suada as the
          platform and service provider for the Customer, such details being
          sufficient under Article 14.
        </p>
        <p>
          6.3 For further compliance, Suada will, at the initial registration of
          a Consumer to use Suada’s platform, software and services through the
          benefit of their contract with the Customer, obtain a clear positive
          consent of that Consumer allowing and agreeing to Suada’s collection,
          use and processing of such personal data for the specific purposes
          which will be indicated (and any special personal data being so
          collected will have its own separate clear description as to purpose,
          how it is held and for how long.
        </p>
        <p>
          6.4 Suada engages third party PR and marketing agencies to promote
          Suada, including through printed matter and by email sent to
          individuals. Suada is aware of the individual’s consent, whether
          Customer, Consumer or otherwise, that it needs to do this. All such
          recipient databases containing those individuals who have consented to
          receiving such information will be held by the third party agency and
          in addition any emails sent out will include the appropriate notices
          concerning continuing consent.
        </p>
        <p>
          6.5 Suada does not intend to sell or pass to a third party any
          personal data for the purposes of that third party’s advertising to
          individuals.
        </p>
        <p>
          6.6 The information in the Subscription Agreement and registration
          with Suada’s platforms will include, amongst other things:
        </p>

        <ul>
          <li>(a) Suada’s details (as a data controller and processor);</li>
          <li>
            (b) details of the purposes for which Suada holds and processes
            personal data and the legal basis for that processing (as set out in
            section 3 above);
          </li>
          <li>(c) the likely recipients of personal data;</li>
          <li>
            (d) the period of time for which Suada intends to hold the data; and
          </li>
          <li>
            (e) any supplementary information required by Article 13 GDPR or by
            other applicable Data Protection Legislation.
          </li>
        </ul>
        <p>
          6.7 Suada will review this information in its Subscription Agreement
          and website registration processes annually and will amend it to
          reflect any changes in Data Protection Legislation or in Suada’s
          practice.
        </p>
        <p>
          6.8 Suada will keep this approach under review – including taking into
          account any guidance produced by ICO and industry standards set by
          appropriate bodies.
        </p>
        <p>
          6.9 This Policy will be available upon request to all and placed on
          the Suada website and platform.
        </p>
        <h6>7. Rights of Individuals</h6>
        <p>7.1 Data subject access requests</p>
        <ul>
          <li>
            (a) Individuals are entitled to access their personal data held by
            Suada on request (Article 15 GDPR). The response Suada gives to a
            data subject access request must also include certain other
            information, such as the purposes of the processing; the recipients
            (or categories of recipient) to whom the personal data has or will
            be disclosed; and individuals’ rights to have their data corrected,
            deleted or to restrict the processing of their data.
          </li>
          <li>
            (b) Suada has noted that, under GDPR, the information must be
            provided to individuals free of charge and within one month of the
            request.
          </li>
          <li>
            (c) Suada will maintain a record of data subject access requests.
          </li>
        </ul>
        <p>7.2 Right to be forgotten</p>
        <ul>
          <li>
            (a) Under GDPR, individuals have the general right to require Suada
            to erase all data held in respect of them in various circumstances
            (Article 17 GDPR). The circumstances include if the individual
            withdraws consent to processing the data, the retention no longer
            being necessary for the original purpose for which it was collected
            and there is no other legitimate ground to justify the processing
            (see section 3 above). However, Suada need not delete the data if an
            exception applies, including that the processing is necessary to
            comply with a legal obligation.
          </li>
          <li>
            (b) Suada considers it unlikely that any individual will seek to
            exercise this right and has decided to review any request, and take
            advice, should the situation arise. However, the starting
            assumptions will be i) is the data is still necessary to be retained
            for the applying period with regard to the legitimate reason
            exception as described (and for the reasons given) above?; and ii)
            for the establishment, exercise or defence of legal claims in the
            future, whether by or against Suada.
          </li>
        </ul>
        <p>7.3 Right to rectification</p>
        <ul>
          <li>
            (a) Individuals have the right to have incorrect personal data about
            them corrected without undue delay (Article 16 GDPR). Suada
            endeavours to have its data as up to date and correct as possible
            and to comply with the expectations of the ICO. Where an error is
            discovered, Suada already corrects this as soon as possible.
          </li>
        </ul>
        <p>7.4 Right to data portability</p>
        <p>
          Individuals have the right, in certain circumstances, to access their
          data in machine-readable format and, where technically possible, to
          have their data transferred directly from Suada to another data
          controller (Article 20 GDPR). Suada has decided to take no action in
          relation to data portability at the current time but will monitor the
          situation and take advice should this become necessary in future.
        </p>
        <h6>8. System Security Measures</h6>
        <p>
          8.1 System perimeter security will be secure using an advanced
          Firewall device setup to prevent non-essential access via port access
          restrictions. All data is stored on secure servers provided by AWS
          (Amazon Web Services) – please refer to
          https://aws.amazon.com/security/. The Firewall provides an Intrusion
          Prevention System, logging all activity.
        </p>
        <p>
          8.2 Suada will have up to date device and server security. Endpoint
          devices are protected with TLS 1.2 (SHA256) protocol security software
          which includes protection for the following:
        </p>
        <ul>
          <li>
            (a) data controls - prevents the flow of sensitive data outbound;
          </li>
          <li>(b) device controls – prevents access to ROMS, USB and Wi-Fi;</li>
          <li>
            (c) anti-virus – protects the device from malicious content and
            files types including Malware, Phishing and Viruses;
          </li>
          <li>
            (d) web controls – prevents access to websites classified as
            potentially dangerous and/or offensive; and
          </li>
          <li>
            (e) ‘Windows’ updates – device operating systems (i.e. ‘Windows’)
            will be kept patched up to date using the ‘Windows Update Service’.
          </li>
        </ul>
        <p>
          8.3 User access to Suada’s systems will be controlled with a best
          practice “strong” password policy, which includes password complexity
          and renewal period rules. Access to application software will be
          controlled with two factor authentication rules.
        </p>
        <p>
          8.4 Suada will use G-Suite, supplied and provided by Google (please
          refer to{' '}
          <a href="https://gsuite.google.co.uk/intl/en_uk/security/?secure-by-design_activeEl=data-centers">
            https://gsuite.google.co.uk/intl/en_uk/security/?secure-by-design_activeEl=data-centers
          </a>
          ) Email Security’ which gives extensive email security measures. These
          include:
        </p>
        <ul>
          <li>
            (a) targeted threat protection – sandbox for both email attachments
            and URLs within emails providing additional protection from
            Ransomware style attacks and other types of malicious threats;
          </li>
          <li>
            (b) attachment management – this prevents the flow of dangerous file
            types
          </li>
          <li>before reaching endpoint devices</li>
          <li>
            (d) strong anti-spam protection following rules based policies.
          </li>
        </ul>
        <h6>9. Data security by Employees</h6>
        <p>
          9.1 The employees all have responsibility to ensure that in performing
          their duties they do not endanger the safety and security of personal
          data Suada holds and processes and at all times act in an appropriate
          manner concerning the Data Protection Legislation generally and their
          individual obligations.
        </p>
        <p>
          9.2 Suada gives all employees a Privacy Notice which covers not only
          the Privacy Notice required by GDPR Article 14 as regards Suada’s use
          of their own personal data, but also the obligations of Suada which
          they must uphold and adhere to. A ‘Do’s and Don’ts’ list is also given
          to employees. All employees must be aware and cognisant of personal
          data security and confidence and this will be reinforced by training.
        </p>
        <p>
          9.3 All Suada employees will undertake mandatory formal training on
          data protection (and other issues) at suitable intervals and other
          training as Suada considers appropriate.
        </p>
        <p>
          9.4 Suada will undertake Data Protection Impact Assessments (as
          defined in GDPR) (“DPIA”) as and when appropriate.
        </p>

        <h6>10. Use of Data Processors</h6>
        <p>
          10.1 Suada shall ensure that it has a written contract which meets the
          requirements of GDPR in place with each data processor to which it may
          pass personal data to be processed. In particular, Suada will expect
          each data processor to guarantee that it will meet the requirements of
          GDPR and will protect clients’ and other individuals’ rights.
        </p>
        <p>10.2 Before engaging a new data processor, Suada will check that:</p>
        <ul>
          <li>
            (a) the geography and location of the data processor and where the
            personal data will be processed;
          </li>
          <li>
            (b) the data processor has appropriate technical and organisational
            measures in place to keep personal data secure;{' '}
          </li>
          <li>
            (c) the data processor's staff who will be engaged in processing
            personal data in relation to the Scheme are subject to a duty of
            confidentiality and are aware of data protection matters and their
            obligations.
          </li>
        </ul>
        <p>
          10.3 Suada will seek appropriate assurances from each data processor
          as to the security arrangements it has in place. This may take the
          form of:
        </p>
        <ul>
          <li>
            (a) for an existing data processor, a short summary of its key data
            security measures;
          </li>
          <li>
            (b) for a new data processor, before entering into a new contract, a
            short statement of its key data security measures;
          </li>
          <li>
            (c) subsequent confirmation from each continuing data processor
            every 36 months of what, if any, changes there have been to its
            security arrangements.
          </li>
        </ul>
        <p>
          10.4 Suada recognises that its data processors may wish to
          sub-contract some services, which may include sub-contractors
          processing data on behalf of the data processor. Suada will ensure
          that its contract with a data processor wishing to do this will
          contain provisions concerning sub-contracting which meet the
          requirements of GDPR.
        </p>
        <h6>11. Data security breaches</h6>
        <p>
          11.1 Suada takes seriously the need to deal with any data breach
          swiftly and appropriately to minimise or eliminate risk of detrimental
          impact on any data subjects. For this purpose, a data breach may
          include (but is not limited to) unauthorised disclosure of or access
          to personal data; or accidental or unlawful destruction of personal
          data; or loss or alteration of personal data.
        </p>
        <p>
          11.2 Suada shall require its employees and its data processors to
          report data breaches or complaints to Suada’s Data Protection Officer
          promptly and to assist Suada in ensuring compliance with the
          requirements of GDPR.
        </p>
        <p>
          11.3 On being notified of a data breach or complaint, the Suada Data
          Protection Officer will as soon as possible notify Suada’s senior
          management and Suada shall initially deal with it through the process
          outlined in Suada’s GPDR Complaints Policy.
        </p>
        <p>
          11.4 Notwithstanding the initialisation of the procedure outlined in
          Suada’s GDPR Complaints Policy, in any event where a data breach has
          occurred, Suada shall consider whether it is necessary or appropriate
          to notify the Information Commissioner's Office ("ICO") or the
          affected individual in the event of a data breach, and will take
          professional advice as a matter of urgency where required.
        </p>
        <p>
          11.5 Suada will maintain a record of any data breaches and complaints
          and action taken in relation to each breach and complaint in inventory
          form.
        </p>
        <p>
          11.6 Suada will act reasonably in assisting data controllers of
          information it holds and its appointed sub-processors in investigating
          and resolving any breaches of this Policy or GDPR generally and will
          review, update and amend this Policy (and others) in the light and
          context of any breaches or issues arising.
        </p>
        <h6>
          12. Data Protection Officer and Data Protection Impact Assessments
        </h6>
        <p>
          12.1 Suada has considered the sections under Data Protection
          Legislation to appoint a data protection officer ("DPO") or to carry
          out a data protection impact assessment ("DPIA") in certain
          circumstances.
        </p>
        <p>
          12.2 Suada, having considered the possibility of appointing a Data
          Protection Officer as described in GPDR, has concluded that it is
          required to appoint a DPO.
        </p>
        <p>
          12.3 Under GDPR, organisations are required to undertake a DPIA "where
          a type of processing in particular using new technologies, and taking
          into account the nature, scope, context and purposes of the
          processing, is likely to result in a high risk to the rights and
          freedoms of natural persons."
        </p>
        <p>
          12.4 Suada does not believe that at the present time the nature of its
          processing (which - as set out in section 3 above - is fundamentally
          to provide a central database and hub for service users of its
          Consumers as required by its Subscription Agreement obligations is
          such that there is likely to be a high risk to the rights and freedoms
          of individuals and it has concluded that it is not necessary for it to
          undertake any DPIAs at the present time.
        </p>
        <h6>
          This Version 1.1 of this Policy was adopted by the directors of Suada
          Limited on 1st April 2021.
        </h6>
      </Wrapper>
    </>
  )
}

export default Content
